<template>
  <div class="circleCard" v-RandomBgColor  :style="{'background':`url(${itemData.picUrl}) center top/cover no-repeat`}">
    <h3 class="name">{{itemData.name}}</h3>
    <div class="userList">
      <img v-for="item in itemData.userList" :key="item.userId" style="z-index: 9;" :src="item.headimgUrl"  alt="" v-if="item.headimgUrl"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "circleCard",
    props:{
       itemData:Object,
       index:Number
    },
    data() {
      return {};
    },
    methods: {}
  };
</script>

<style lang="less" scoped>
  .circleCard {
    width: 216px;
    height: 216px;
    background-color: #98D99C;
    border-radius: 20px;
    box-sizing: border-box;
    padding-top: 70px;
    text-align: center;

    .name {
      font-size: 36px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #FFFFFF;
      line-height: 50px;
      margin-left: 6px;
      margin-right: 6px;
      text-shadow: 2px 2px 4px #000000;
    }

    .marquee {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      animation: Marquee 5s linear infinite;
    }

    .userList {
      width: 100%;
      padding: 46px 0 0 16px;
      display: flex;

      img {
        display: block;
        width: 34px;
        height: 34px;
        object-fit: cover;
        border: 2px solid #ffffff;
        background-color: #ffffff;
        border-radius: 50%;
        margin-right: -10px !important;
        position: relative;
      }
    }
  }

  @keyframes Marquee {
    0%   { text-indent: 100%; }
    100% { text-indent: -100%; }
  }
</style>
